import {
  threeDPFormatter,
  moneyFormatter,
  percentFormatter,
  nullFormatter
} from 'common/utils/valueFormatters';
import {
  sideStyle,
  percentStyle,
  sideStyleWithGroup
} from 'common/utils/styleChooser';
import EntityMap from 'entities/EntityMap';

const tradeGridColumns = [
  {
    field: 'tradeId',
    headerName: 'Id',
    cellClass: 'number',
    hide: true
  },
  {
    field: 'refId',
    headerName: 'Ref Id',
    enableRowGroup: false,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    pinned: 'left',
    lockPinned: true
  },
  {
    field: 'securityCode',
    headerName: 'Security',
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'tradeDate',
    headerName: 'Trade Date',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'requestTime',
    headerName: 'Req Time',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'side',
    headerName: 'Side',
    enableRowGroup: true,
    cellClass: params =>
      ['BUY', 'COVR'].includes(params.value) ? 'long' : 'short'
  },
  {
    field: 'orderType',
    headerName: 'OrderType',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        MKT: '#FF9E28',
        LMT: 'steelblue'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'qtyPct',
    headerName: 'AUM %',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClassRules: sideStyleWithGroup(),
    aggFunc: 'sum'
  },
  {
    field: 'qtyUsd',
    headerName: 'Value $',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyleWithGroup(),
    aggFunc: 'sum'
  },
  {
    field: 'filledQtyPct',
    headerName: 'Filled AUM %',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClassRules: sideStyleWithGroup(),
    aggFunc: 'sum'
  },
  {
    field: 'filledQtyUsd',
    headerName: 'Filled Value $',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyleWithGroup(),
    aggFunc: 'sum'
  },
  {
    field: 'prevFilledQuantity',
    headerName: 'Prev Filled',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'filledQuantity',
    headerName: 'Qty Filled',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'filledPct',
    headerName: 'Filled %',
    cellRenderer: 'percentBarComponent',
    cellClass: 'number'
  },
  {
    field: 'executionAvgPrice',
    headerName: 'Exec Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'limitPriceLocal',
    headerName: 'Lmt Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'endPriceLocal',
    headerName: 'Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    hide: true
  },
  {
    field: 'dtdPriceChange',
    headerName: 'Price %',
    valueFormatter: percentFormatter,
    cellClassRules: percentStyle()
  },
  {
    field: 'pmCode',
    headerName: 'PM Name',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'pmStatus',
    headerName: 'Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        SUBMIT: 'mediumseagreen',
        WITHDRAW: 'crimson',
        MODIFY: 'coral'
      }
    }
  },
  {
    field: 'advisorName',
    headerName: 'Advisor Name',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    enableRowGroup: true,
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'complianceStatus',
    headerName: 'Cpl Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        FAIL: 'crimson',
        SKIP: '#f50',
        PASS: '#87d068',
        WARN: '#eb9234'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'complianceError',
    headerName: 'Cpl Error',
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'traderCode',
    headerName: 'Trdr Name',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'traderStatus',
    headerName: 'Trdr Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        REJECT: 'crimson',
        APPROVE: 'lightseagreen'
      }
    }
  },
  {
    field: 'traderFeedback',
    headerName: 'Trdr Feedback',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'pmRemark',
    headerName: 'PM Remark',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'pmReason',
    headerName: 'PM Reason',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'requestType',
    headerName: 'Req Type',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        TRD: 'steelblue',
        IPO: 'crimson'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'requestStatus',
    headerName: 'Req Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        ACTIVE: 'steelblue',
        ARCHIVE: 'lightslategrey'
      }
    }
  },
  {
    field: 'orderStatus',
    headerName: 'Ord Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        SENT: 'lightseagreen',
        FILLED: 'mediumseagreen',
        PARTFILL: 'steelblue',
        REJECTED: 'crimson',
        CANCEL: '#f50',
        WORKING: '#87d068'
      }
    }
  },
  {
    field: 'tradeSource',
    headerName: 'Source',
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'algoCode',
    headerName: 'Algo',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'timeInForce',
    headerName: 'TIF',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        DAY: 'steelblue',
        GTC: '#87d068'
      }
    }
  },
  {
    field: 'country',
    headerName: 'Country',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'basket',
    headerName: 'Basket',
    cellClass: 'non-number',
    enableRowGroup: true,
    hide: true
  },
  {
    field: 'filledTime',
    headerName: 'Filled Time',
    cellClass: 'non-number',
    enableRowGroup: true,
    hide: true
  },
  {
    field: 'pnlBookFxRate',
    headerName: 'Pnl Book FxRate',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  }
];

export const tradeGridColumnMap = EntityMap.fromArray(
  tradeGridColumns,
  'field'
);
