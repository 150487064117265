import React, { Component } from 'react';
import {
  Modal,
  Button,
  InputNumber,
  Row,
  Col,
  Cascader,
  Spin,
  Badge
} from 'antd';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import EntityMap from 'entities/EntityMap';
import Portfolio from '../../entities/Portfolio';
import { UndoOutlined } from '@ant-design/icons';
import {
  PortfolioCalculator,
  MV_GROUP,
  MV_TYPE,
  MV_SOURCE,
  POS_DIR,
  parseMvAdjType,
  TRD_FACTOR,
  parseTrdFactorType,
  POS_FIELD_CALC_CFG
} from '../../entities/calculators/PortfolioCalculator';
import { DIALOG_ADJUST_PORTFOLIO } from 'features/order/orderConstants';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import numeral from 'numeral';
import { posGridColumns } from './GridColumnMap';
import client from '../../api/client';
import { roleTradeLabel } from '../../../../common/utils/DomainUtils';

const ADJ_VAL_TYPE = {
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative'
};

class AdjustPortfolioDialog extends Component {
  state = {
    submitStatus: 'READY',
    selectedPos: null,
    fields: {
      [MV_GROUP.LONG]: {
        [ADJ_VAL_TYPE.ABSOLUTE]: 0,
        [ADJ_VAL_TYPE.RELATIVE]: 0
      },
      [MV_GROUP.SHORT]: {
        [ADJ_VAL_TYPE.ABSOLUTE]: 0,
        [ADJ_VAL_TYPE.RELATIVE]: 0
      }
    },

    fundBookOptions: [],
    selectedFundBook: '',
    portfolio: {},
    portfolioSummary: {},
    posGridSettings: hotTableUtils.createSettings({
      columns: posGridColumns
    }),
    posGridWrapperStyle: {
      width: '100%',
      height: '380px',
      marginTop: '5px',
      marginBottom: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    }
  };

  componentDidMount() {
    this._debouncedSubmit = _.debounce(this._onSubmit, 500);
    this._debouncedCalcAll = _.debounce(this._calcAll, 500);

    this._init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  _init = () => {
    const {
      settings: { funds }
    } = this.props;
    const fundBookOptions = funds
      .map(f => {
        const books = f.books ? f.books.filter(r => r.enableTxn) : [];
        if (_.isEmpty(books)) return null;
        const fundBook = { value: f.name, label: f.name };
        fundBook.children = f.books
          .map(
            b =>
              b.enableTxn && {
                value: b.name,
                label: b.name
              }
          )
          .filter(r => r);
        return fundBook;
      })
      .filter(f => f);
    const firstFund = _.first(funds);
    const firstBook = _.first((firstFund || {}).books);

    const portResult = this._generatePortfolio(firstFund.name, firstBook.name);

    this.setState({
      fundBookOptions,

      ...portResult
    });
  };

  _generatePortfolio = (fund, book) => {
    const {
      livePositionMap,
      liveRiskInfoMap,
      settings: { preference }
    } = this.props;
    const positions = EntityMap.map(livePositionMap || {}).filter(
      p => p.fundCode === fund && p.bookCode === book
    );

    const selectedFundBook = `${fund}-${book}`;
    const riskInfo = liveRiskInfoMap.byKey[selectedFundBook];
    const portfolio = Portfolio.createFrom(positions, riskInfo, preference);
    const portfolioSummary = PortfolioCalculator.calcPortfolioSummary(
      portfolio
    );

    const { portfolioMvs } = portfolioSummary;
    const tgtLongMvPct =
      portfolioMvs[parseMvAdjType(MV_GROUP.LONG, MV_TYPE.MV, MV_SOURCE.TGT)];
    const tgtShortMvPct =
      portfolioMvs[parseMvAdjType(MV_GROUP.SHORT, MV_TYPE.MV, MV_SOURCE.TGT)];

    return {
      portfolio,
      portfolioSummary,
      riskInfo,
      selectedFundBook,
      fields: {
        [MV_GROUP.LONG]: {
          [ADJ_VAL_TYPE.ABSOLUTE]: _.round(tgtLongMvPct, 0),
          [ADJ_VAL_TYPE.RELATIVE]: 0
        },
        [MV_GROUP.SHORT]: {
          [ADJ_VAL_TYPE.ABSOLUTE]: _.round(tgtShortMvPct, 0),
          [ADJ_VAL_TYPE.RELATIVE]: 0
        }
      }
    };
  };

  _onBookChange = value => {
    const [fund, book] = value;

    const portResult = this._generatePortfolio(fund, book);
    this.setState({
      ...portResult
    });
  };

  _handleChanges = (dir, changes) => {
    const { portfolio } = this.state;
    const positions = portfolio[dir] || [];
    let reCalcPortfolioSummary = false;
    const updatedPositions = changes.reduce((prev, c) => {
      const [row, field, oldValue, newValue] = c;
      if (oldValue === newValue) return prev;

      return prev.map((p, i) => {
        if (i === row) {
          if (Object.keys(POS_FIELD_CALC_CFG).includes(field)) {
            reCalcPortfolioSummary = true;
            const newNumValue = _.toNumber(newValue);
            return PortfolioCalculator.calcPosition(
              {
                ...p,
                [field]: _.isFinite(newNumValue) ? newNumValue : newValue
              },
              field
            );
          }

          if (['isLocked'].includes(field)) {
            reCalcPortfolioSummary = true;
            return {
              ...p,
              [field]: newValue,
              lockedTgtMvPct: newValue ? p.tgtMvPct : 0,
              lockedTgtBetaMvPct: newValue ? p.tgtBetaMvPct : 0
            };
          }

          if (['orderType'].includes(field)) {
            return {
              ...p,
              [field]: newValue,
              limitPriceLocal: newValue === 'LMT' ? p.endPriceLocal : null
            };
          }

          return { ...p, [field]: newValue };
        }
        return p;
      });
    }, positions);

    const updatedPortfolio = {
      ...portfolio,
      [dir]: updatedPositions
    };

    if (reCalcPortfolioSummary) {
      const portfolioSummary = PortfolioCalculator.calcPortfolioSummary(
        updatedPortfolio
      );
      this.setState({
        portfolio: updatedPortfolio,
        portfolioSummary
      });
    } else {
      this.setState({
        portfolio: updatedPortfolio
      });
    }
  };

  _beforeCellChange = (dir, changes, source) => {
    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) this._handleChanges(dir, realChanges);

    return false;
  };

  _calcAll = (dir, mvType, value) => {
    const { portfolio } = this.state;

    const mvAdjType = parseMvAdjType(dir, mvType, MV_SOURCE.TGT);
    const oldValue = this._extractMvPct(dir, mvType, MV_SOURCE.CUR);

    const portResult = PortfolioCalculator.calcAll(
      portfolio,
      mvAdjType,
      value || 0,
      oldValue
    );

    this.setState({
      ...portResult
    });
  };

  _onAdjustFieldChange = (dir, mvType, rawValue, valueType) => {
    const { fields, portfolio } = this.state;

    this.setState({
      fields: {
        ...fields,
        [dir]: {
          ...fields[dir],
          [valueType]: rawValue
        }
      }
    });

    const value =
      valueType === ADJ_VAL_TYPE.ABSOLUTE
        ? rawValue
        : PortfolioCalculator.calcTargetMvPctByRelative(
            portfolio,
            dir,
            mvType,
            rawValue
          );

    this._debouncedCalcAll(dir, mvType, value);
  };

  _createAdjustFields = dir => {
    const { fields } = this.state;
    const absoluteMvInput = fields[dir][ADJ_VAL_TYPE.ABSOLUTE];
    const relativeMvInput = fields[dir][ADJ_VAL_TYPE.RELATIVE];

    const mvCurStr = this._extractMvPct(dir, MV_TYPE.MV, MV_SOURCE.CUR, true);
    const mvTgtStr = this._extractMvPct(dir, MV_TYPE.MV, MV_SOURCE.TGT, true);
    const mvCur = this._extractMvPct(dir, MV_TYPE.MV, MV_SOURCE.CUR);
    const mvCls = this._extractMvPct(dir, MV_TYPE.MV, MV_SOURCE.CLS);

    const minMvTgtRestriction = dir === POS_DIR.LONG ? { min: 0 } : { max: 0 };

    const betaMvCurStr = this._extractMvPct(
      dir,
      MV_TYPE.BETA_MV,
      MV_SOURCE.CUR,
      true
    );
    // const betaMvCur = this._extractMvPct(dir, MV_TYPE.BETA_MV, MV_SOURCE.CUR);
    // const betaMvTgtLocked = this._extractMvPct(
    //   dir,
    //   MV_TYPE.BETA_MV,
    //   MV_SOURCE.LOCKED
    // );
    const betaMvTgtStr = this._extractMvPct(
      dir,
      MV_TYPE.BETA_MV,
      MV_SOURCE.TGT,
      true
    );
    // const betaMvCls = this._extractMvPct(dir, MV_TYPE.BETA_MV, MV_SOURCE.CLS);
    // const minBetaMvTgt = betaMvCur - betaMvCls + betaMvTgtLocked;
    // const minBetaMvTgtRestriction =
    //   dir === POS_DIR.LONG ? { min: minBetaMvTgt } : { max: minBetaMvTgt };

    const cssClss = dir === POS_DIR.LONG ? 'long' : 'short';

    return (
      <div className="summary">
        <span className="comment">Input your target: </span>
        <InputNumber
          value={absoluteMvInput}
          // formatter={value => `${numeral(value).format('0.00')}`}
          // parser={value => value.replace('%', '')}
          onChange={value =>
            this._onAdjustFieldChange(
              dir,
              MV_TYPE.MV,
              value,
              ADJ_VAL_TYPE.ABSOLUTE
            )
          }
          {...minMvTgtRestriction}
        />
        % <span className="comment">or relative: </span>
        <InputNumber
          value={relativeMvInput}
          // formatter={value => `${numeral(value).format('0.00')}`}
          // parser={value => value.replace('%', '')}
          onChange={value =>
            this._onAdjustFieldChange(
              dir,
              MV_TYPE.MV,
              value,
              ADJ_VAL_TYPE.RELATIVE
            )
          }
        />
        %<span className={`${cssClss}`}>{` ${dir}`}</span> MV%:
        <span className={`${cssClss}`}>{` ${mvCurStr} => ${mvTgtStr} `}</span>
        (non-tradable MV%:{' '}
        <span className={`${cssClss}`}>{` ${numeral(
          (mvCur - mvCls) / 100
        ).format('0.00%')}`}</span>
        ) and β MV%:
        <span
          className={`${cssClss}`}
        >{` ${betaMvCurStr} => ${betaMvTgtStr} `}</span>
        {/* <InputNumber
          value={betaMvTgt}
          formatter={value => `${numeral(value).format('0.00')}`}
          // parser={value => value.replace('%', '')}
          onChange={value =>
            this._onAdjustFieldChange(dir, MV_TYPE.BETA_MV, value)
          }
          {...minBetaMvTgtRestriction}
        />
        % (min β MV%:{' '}
        <span className={`${cssClss}`}>{` ${numeral(minBetaMvTgt / 100).format(
          '0.00%'
        )}`}</span>
        ) */}
      </div>
    );
  };

  _onPosSelectionChanged = (dir, row) => {
    const { portfolio, selectedPos } = this.state;
    const positions = portfolio[dir] || [];
    const pos = positions[row];
    if (pos === selectedPos) return;

    this.setState({
      selectedPos: pos
    });
  };

  _createPositionsGrid = dir => {
    const {
      posGridWrapperStyle,
      posGridSettings,

      portfolio
    } = this.state;

    const positions = portfolio[dir] || [];

    return (
      positions.length > 0 && (
        <div>
          {this._createAdjustFields(dir)}

          <div style={posGridWrapperStyle}>
            <HotTable
              data={positions}
              beforeChange={_.partial(this._beforeCellChange, dir)}
              manualColumnResize={true}
              columnSorting={true}
              afterSelectionEnd={_.partial(this._onPosSelectionChanged, dir)}
              {...posGridSettings}
            ></HotTable>
          </div>
        </div>
      )
    );
  };

  _extractMvPct = (group, mvType, src, toStr = false) => {
    const {
      portfolioSummary: { portfolioMvs = {} }
    } = this.state;
    const valPct = portfolioMvs[parseMvAdjType(group, mvType, src)];
    return toStr ? numeral(valPct / 100).format('0.00%') : _.toNumber(valPct);
  };

  _extractTradeFactor = (group, factor) => {
    const {
      portfolioSummary: { tradeSummary = {} }
    } = this.state;
    const type = parseTrdFactorType(group, factor);
    const val = tradeSummary[type];
    return _.isUndefined(val) ? 0 : val;
  };

  _onReset = () => {
    const { selectedFundBook } = this.state;
    this._onBookChange(selectedFundBook.split('-'));
  };

  _createOperationBar = () => {
    const { fundBookOptions, selectedFundBook } = this.state;
    const [fund, book] = selectedFundBook.split('-');

    return (
      <Row gutter={8}>
        <Col span={3}>
          <Cascader
            placeholder="Select Book..."
            value={[fund, book]}
            options={fundBookOptions}
            onChange={v => this._onBookChange(v)}
            allowClear={false}
          />
        </Col>
        <Col span={18}></Col>
        <Col span={3}>
          <Button
            icon={<UndoOutlined />}
            onClick={this._onReset}
            type="primary"
            style={{ float: 'right' }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    );
  };

  _createPortfolioSummaryPanel = () => {
    const grossMvCurStr = this._extractMvPct(
      MV_GROUP.GROSS,
      MV_TYPE.MV,
      MV_SOURCE.CUR,
      true
    );
    const grossMvTgtStr = this._extractMvPct(
      MV_GROUP.GROSS,
      MV_TYPE.MV,
      MV_SOURCE.TGT,
      true
    );
    const netBetaMvCurStr = this._extractMvPct(
      MV_GROUP.NET,
      MV_TYPE.BETA_MV,
      MV_SOURCE.CUR,
      true
    );
    const netBetaMvTgtStr = this._extractMvPct(
      MV_GROUP.NET,
      MV_TYPE.BETA_MV,
      MV_SOURCE.TGT,
      true
    );

    const tradeCount =
      this._extractTradeFactor(POS_DIR.LONG, TRD_FACTOR.COUNT) +
      this._extractTradeFactor(POS_DIR.SHORT, TRD_FACTOR.COUNT);
    const tradePct =
      this._extractTradeFactor(POS_DIR.LONG, TRD_FACTOR.TURNOVER) +
      this._extractTradeFactor(POS_DIR.SHORT, TRD_FACTOR.TURNOVER);

    return (
      <Row gutter={8}>
        <Col span={3}></Col>
        <Col span={18}>
          <div className="summary">
            Total GROSS MV%:
            <span className="keyword">{` ${grossMvCurStr} => ${grossMvTgtStr} `}</span>
            and NET β MV%:
            <span className="keyword">{` ${netBetaMvCurStr} => ${netBetaMvTgtStr} `}</span>
            (<span className="comment">{`Totally ${tradeCount}`}</span> trades
            would be created and trade% is{' '}
            <span className="comment">{`${numeral(tradePct / 100).format(
              '0.00%'
            )}`}</span>
            )
          </div>
        </Col>
        <Col span={3}></Col>
      </Row>
    );
  };

  _closeDialog = () => {
    this.props.closeDialog(DIALOG_ADJUST_PORTFOLIO);
  };

  _onSubmit = () => {
    const { portfolio } = this.state;

    const trades = Portfolio.createTrades(portfolio);

    if (_.isEmpty(trades)) {
      this.closeDialog();
      return;
    }

    const { submitDialogSuccess } = this.props;

    this.setState({ submitStatus: 'SUBMITTING' });
    client
      .addTrades(trades)
      .then(result => {
        _.zip(trades, result).forEach(([t, r]) => {
          t.tradeId = r.id;
          t.refId = r.refId;
        });

        submitDialogSuccess(DIALOG_ADJUST_PORTFOLIO, {
          trades
        });
        this.setState({ submitStatus: 'READY' });
      })
      .catch(_ => {
        this.setState({
          submitStatus: 'ERROR',
          serverErrMsg: 'Server Error! Please contact system administrator.'
        });
      });
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, portfolio } = this.state;
    const count = _.sum(
      _.flatten(Object.values(portfolio)).map(p => _.size(p.errors))
    );

    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          disabled={count > 0}
        >
          Submit
        </Button>
      )
    }[submitStatus];

    return (
      <Badge key="submitBadge" count={count} style={{ marginRight: '10px' }}>
        {submitBtn}
      </Badge>
    );
  };

  _createErrorsPanel = () => {
    const { selectedPos } = this.state;
    const { errors = {} } = selectedPos || {};

    const errorMsgs = Object.values(errors);

    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  render() {
    const { user = {} } = this.props.settings;
    return (
      <Modal
        width={1600}
        maskClosable={false}
        title={`Adjust Portfolio ${roleTradeLabel(user)}`}
        visible={true}
        onOk={this._closeDialog}
        onCancel={this._closeDialog}
        footer={[
          this._createSubmitBtn(this._debouncedSubmit),
          <Button
            key="close"
            type="primary"
            onClick={this._closeDialog}
            style={{ marginLeft: '10px' }}
          >
            Close
          </Button>
        ]}
      >
        <Spin tip="Initializing..." spinning={false}>
          {this._createOperationBar()}

          {this._createPositionsGrid(POS_DIR.LONG)}
          {this._createPositionsGrid(POS_DIR.SHORT)}

          {this._createPortfolioSummaryPanel()}
          {this._createErrorsPanel()}
        </Spin>
      </Modal>
    );
  }
}

export default AdjustPortfolioDialog;
