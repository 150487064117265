import React, { Component } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Cascader,
  Spin,
  Badge,
  Radio,
  Switch
} from 'antd';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import EntityMap from 'entities/EntityMap';
import { DIALOG_ROLL_POSITIONS } from 'features/order/orderConstants';
import { UndoOutlined } from '@ant-design/icons';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import agGridUtils from 'common/ui/agGridUtils';
import { rollPosGridColumns } from './GridColumnMap';
import client from '../../api/client';
import { isBetweenDays } from 'common/utils/DateUtils';
import RolledPosition from '../../entities/RolledPosition';
import RolledPosValidator from '../../entities/validators/RolledPosValidator';
import { roleTradeLabel } from '../../../../common/utils/DomainUtils';
import { AgGridReact } from 'ag-grid-react';
import { trackOrdGridColumns } from './GridColumnMap';

const SUPPORTED_INST_CLASSES = new Set([
  'EQINDX_FT',
  'EQTY_FT',
  'CMDTY_FT',
  'FX_FT'
]);
const ROLL_DAYS_OPTIONS = [
  { text: '3D', value: 3 },
  { text: '5D', value: 5 },
  { text: '10D', value: 10 },
  { text: 'ALL', value: -1 }
];

class RollPositionsDialog extends Component {
  state = {
    submitStatus: 'READY',
    positions: [],
    selectedPos: null,
    rollDays: 5,
    byPos: true,

    fundBookOptions: [],
    selectedFundBook: '',
    posGridSettings: hotTableUtils.createSettings({
      columns: rollPosGridColumns,
      contextMenu: {
        items: {
          remove_row: {}
        }
      }
    }),
    posGridWrapperStyle: {
      width: '100%',
      height: '600px',
      marginTop: '5px',
      marginBottom: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    trackOrdGridSettings: agGridUtils.createSettings({
      columnDefs: trackOrdGridColumns,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    trackOrdGridWrapperStyle: {
      width: '100%',
      height: '390px'
    }
  };

  async componentDidMount() {
    this._debouncedSubmit = _.debounce(this._onSubmit, 500);

    await this._init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  _init = async () => {
    const {
      settings: { funds }
    } = this.props;
    const { rollDays } = this.state;

    const fundBookOptions = funds
      .map(f => {
        const books = f.books ? f.books.filter(r => r.enableTxn) : [];
        if (_.isEmpty(books)) return null;
        const fundBook = { value: f.name, label: f.name };
        fundBook.children = f.books
          .map(
            b =>
              b.enableTxn && {
                value: b.name,
                label: b.name
              }
          )
          .filter(r => r);
        return fundBook;
      })
      .filter(f => f);
    const firstFund = _.first(funds);
    const firstBook = _.first((firstFund || {}).books);

    const rollCtx = await this._calcRollCtx(firstFund.name, firstBook.name);
    const positions = this._createRollPositions(
      firstFund.name,
      firstBook.name,
      rollDays,
      rollCtx
    );
    const selectedFundBook = `${firstFund.name}-${firstBook.name}`;

    this.setState({
      fundBookOptions,
      selectedFundBook,

      positions,
      rollCtx
    });
  };

  _calcRollCtx = async (fund, book) => {
    const { livePositionMap } = this.props;
    const closeTickers = EntityMap.map(livePositionMap || {})
      .filter(
        p =>
          p.fundCode === fund &&
          p.bookCode === book &&
          p.targetQuantityEndForClose !== 0 &&
          SUPPORTED_INST_CLASSES.has(p.instrumentClass)
      )
      .map(p => p.ticker);

    const rollCtx = await client.calcRollContext({
      bookCode: book,
      closeTickers
    });

    return rollCtx;
  };

  _validatePositions = positions => {
    return positions.map(p => {
      const errors = RolledPosValidator.validate(p);
      return {
        ...p,

        errors,
        hasErrors: !_.isEmpty(errors)
      };
    });
  };

  _createRollPositions = (fund, book, rollDays, rollCtx, byPos = true) => {
    const { livePositionMap, liveRiskInfoMap } = this.props;
    const positions = EntityMap.map(livePositionMap || {}).filter(
      p =>
        p.fundCode === fund &&
        p.bookCode === book &&
        p.targetQuantityEndForClose !== 0 &&
        SUPPORTED_INST_CLASSES.has(p.instrumentClass) &&
        (_.isNil(p.maturityDate) ||
          rollDays < 0 ||
          isBetweenDays(p.maturityDate, null, rollDays))
    );

    const selectedFundBook = `${fund}-${book}`;
    const riskInfo = liveRiskInfoMap.byKey[selectedFundBook];

    // const openTickerMap = {};
    // let cachedGroupRefData = {};
    // positions.forEach(
    //   r =>
    //     (openTickerMap[r.ticker] = TickerUtils.parseNextMonthFutureTicker(
    //       r.ticker
    //     ))
    // );
    // if (!_.isEmpty(openTickerMap)) {
    //   cachedGroupRefData = await client.getCachedGroupRefData({
    //     tickers: Object.values(openTickerMap),
    //     groupType: 'ROLL_FUT'
    //   });
    // }

    const { rollInfos } = rollCtx;
    const rollInfoMap = _.keyBy(rollInfos, r => r.closeTicker);

    return _.orderBy(
      positions
        .map(p => {
          // const openTicker = openTickerMap[p.ticker];
          // const openMultiplier =
          //   cachedGroupRefData &&
          //   cachedGroupRefData[openTicker] &&
          //   cachedGroupRefData[openTicker]['PX_POS_MULT_FACTOR']
          //     ? parseInt(cachedGroupRefData[openTicker]['PX_POS_MULT_FACTOR'], 10)
          //     : parseInt(p.multiplier, 10);
          return RolledPosition.createFrom(
            p,
            riskInfo.nav,
            rollInfoMap[p.ticker],
            byPos
            // openTicker,
            // openMultiplier
          );
        })
        .filter(Boolean),
      ['ticker'],
      ['asc']
    );
  };

  _onBookChange = async value => {
    const [fund, book] = value;
    const { rollDays } = this.state;

    const rollCtx = await this._calcRollCtx(fund, book);
    const positions = this._createRollPositions(fund, book, rollDays, rollCtx);
    const selectedFundBook = `${fund}-${book}`;

    this.setState({
      selectedFundBook,
      positions,
      rollCtx,
      byPos: true
    });
  };

  _closeDialog = () => {
    this.props.closeDialog(DIALOG_ROLL_POSITIONS);
  };

  _onSubmit = () => {
    const { positions } = this.state;
    const trades = _.flatMap(positions, p => RolledPosition.toTrades(p));

    if (_.isEmpty(trades)) {
      this.closeDialog();
      return;
    }

    const { submitDialogSuccess } = this.props;

    this.setState({ submitStatus: 'SUBMITTING' });
    client
      .addTrades(trades)
      .then(result => {
        _.zip(trades, result).forEach(([t, r]) => {
          t.tradeId = r.id;
          t.refId = r.refId;
        });

        submitDialogSuccess(DIALOG_ROLL_POSITIONS, {
          trades
        });
        this.setState({ submitStatus: 'READY' });
      })
      .catch(_ => {
        this.setState({
          submitStatus: 'ERROR',
          serverErrMsg: 'Server Error! Please contact system administrator.'
        });
      });
  };

  _onReset = async () => {
    const { selectedFundBook } = this.state;
    await this._onBookChange(selectedFundBook.split('-'));
  };

  _createRadioButtons = options => {
    return options.map(o => (
      <Radio.Button key={o.text} value={o.value}>
        {o.text}
      </Radio.Button>
    ));
  };

  _onChangeRollDays = e => {
    const rollDays = e.target.value;
    const { selectedFundBook, rollCtx, byPos } = this.state;
    const [fund, book] = selectedFundBook.split('-');
    const positions = this._createRollPositions(
      fund,
      book,
      rollDays,
      rollCtx,
      byPos
    );

    this.setState({
      rollDays,
      positions
    });
  };

  _onSwitchChange = () => {
    const { selectedFundBook, rollDays, rollCtx, byPos } = this.state;
    const [fund, book] = selectedFundBook.split('-');
    const positions = this._createRollPositions(
      fund,
      book,
      rollDays,
      rollCtx,
      !byPos
    );

    this.setState({
      positions,
      byPos: !byPos
    });
  };

  _createOperationBar = () => {
    const { fundBookOptions, selectedFundBook, rollDays, byPos } = this.state;
    const [fund, book] = selectedFundBook.split('-');

    return (
      <Row gutter={8}>
        <Col span={3}>
          <Cascader
            placeholder="Select Book..."
            value={[fund, book]}
            options={fundBookOptions}
            onChange={v => this._onBookChange(v)}
            allowClear={false}
          />
        </Col>
        <Col span={3}>
          <Radio.Group
            onChange={this._onChangeRollDays}
            value={rollDays}
            buttonStyle={'solid'}
          >
            {this._createRadioButtons(ROLL_DAYS_OPTIONS)}
          </Radio.Group>
        </Col>
        <Col span={3}>
          {book === 'W08' && (
            <Switch
              checked={byPos}
              checkedChildren="ByPos"
              unCheckedChildren="ByOrd"
              onChange={this._onSwitchChange}
              style={{ float: 'left' }}
            ></Switch>
          )}
        </Col>
        <Col span={12}></Col>
        <Col span={3}>
          <Button
            icon={<UndoOutlined />}
            onClick={this._onReset}
            type="primary"
            style={{ float: 'right' }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    );
  };

  _onPosSelectionChanged = row => {
    const { positions, selectedPos } = this.state;
    const pos = positions[row];
    if (pos === selectedPos) return;

    this.setState({
      selectedPos: pos
    });
  };

  _beforeRemoveRow = (row, count, rows, source) => {
    let { positions } = this.state;
    positions = positions.filter((_, i) => !rows.includes(i));

    this.setState({ positions });

    return false;
  };

  _handleChanges = changes => {
    const { positions } = this.state;
    const updatedPositions = this._validatePositions(
      changes.reduce((prev, c) => {
        const [row, field, oldValue, newValue] = c;
        if (oldValue === newValue) return prev;

        return prev.map((p, i) => {
          if (i === row) {
            if (['closeTradeQty', 'openMultiplier'].includes(field)) {
              const updatedPos = { ...p, [field]: parseInt(newValue, 10) };
              const openTradeQty = parseInt(
                updatedPos.closeTradeQty *
                  (updatedPos.multiplier / updatedPos.openMultiplier),
                10
              );
              const pmReason = RolledPosition.calcPmReason(updatedPos);
              return {
                ...updatedPos,
                openTradeQty,
                pmReason
              };
            }

            if (['dvd'].includes(field)) {
              const {
                rollInfo: { spread = 0, closePx = 0 }
              } = p;
              const dvd = parseFloat(newValue);
              const spreadPct = !closePx
                ? null
                : _.round(((spread + dvd) / closePx) * 100, 2);
              return {
                ...p,
                [field]: dvd,
                spreadPct
              };
            }

            if (['rollPct'].includes(field)) {
              const { clsQty, multiplier, openMultiplier } = p;
              const rollPct = parseFloat(newValue);
              const closeTradeQty = Math.ceil(
                Math.abs((clsQty * (rollPct || 0)) / 100)
              );
              const openTradeQty = parseInt(
                closeTradeQty * (multiplier / openMultiplier),
                10
              );

              const updatedPos = {
                ...p,
                [field]: rollPct,
                closeTradeQty,
                openTradeQty
              };
              const pmReason = RolledPosition.calcPmReason(updatedPos);
              return {
                ...updatedPos,
                pmReason
              };
            }

            return { ...p, [field]: newValue };
          }
          return p;
        });
      }, positions)
    );

    this.setState({
      positions: updatedPositions
    });
  };

  _beforeCellChange = (changes, source) => {
    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) this._handleChanges(realChanges);

    return false;
  };

  _beforeColumnSort = (currentSortConfig, destSortConfig) => {
    const { positions } = this.state;

    const sortOrders = destSortConfig.map(c => c.sortOrder);
    const columns = destSortConfig.map(c => rollPosGridColumns[c.column].data);
    const sortedPostions = _.orderBy(positions, columns, sortOrders);
    this.setState({
      positions: sortedPostions
    });
    return true;
  };

  _createPositionsGrid = () => {
    const {
      posGridWrapperStyle,
      posGridSettings,

      positions
    } = this.state;

    return (
      <div>
        <div style={posGridWrapperStyle}>
          <HotTable
            data={positions}
            beforeChange={this._beforeCellChange}
            manualColumnResize={true}
            afterSelectionEnd={this._onPosSelectionChanged}
            beforeRemoveRow={this._beforeRemoveRow}
            columnSorting={true}
            beforeColumnSort={this._beforeColumnSort}
            // filters={true}
            // dropdownMenu={true}
            {...posGridSettings}
          ></HotTable>
        </div>
      </div>
    );
  };

  _onTrackOrdGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  _createTrackOrdGrid = () => {
    const {
      byPos,
      rollCtx,
      trackOrdGridSettings,
      trackOrdGridWrapperStyle
    } = this.state;
    if (byPos) return;

    const { trackOrders = [] } = rollCtx;

    return (
      <div
        style={trackOrdGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={trackOrders}
          onGridReady={this._onTrackOrdGridReady}
          {...trackOrdGridSettings}
        />
      </div>
    );
  };

  _createSummaryPanel = () => {
    const { positions } = this.state;
    const tradeCount = _.reduce(
      positions,
      (r, p) =>
        r + (p.closeTradeQty > 0 ? 1 : 0) + (p.openTradeQty > 0 ? 1 : 0),
      0
    );

    return (
      <Row gutter={8}>
        <Col span={3}></Col>
        <Col span={18}>
          <div className="summary">
            <span className="comment">{`Totally ${tradeCount}`}</span> trades
            would be created.
          </div>
        </Col>
        <Col span={3}></Col>
      </Row>
    );
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, positions } = this.state;
    const count = _.sum(positions.map(p => _.size(p.errors)));

    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          disabled={count > 0}
        >
          Submit
        </Button>
      )
    }[submitStatus];

    return (
      <Badge key="submitBadge" count={count} style={{ marginRight: '10px' }}>
        {submitBtn}
      </Badge>
    );
  };

  _createErrorsPanel = () => {
    const { selectedPos } = this.state;
    const { errors = {} } = selectedPos || {};

    const errorMsgs = Object.values(errors);

    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  render() {
    const { user = {} } = this.props.settings;
    // const { byPos } = this.state;
    // const splitPaneTopSize = byPos ? '100%' : '70%';
    return (
      <Modal
        width={1900}
        maskClosable={false}
        title={`Roll Positions ${roleTradeLabel(user)}`}
        visible={true}
        onOk={this._closeDialog}
        onCancel={this._closeDialog}
        footer={[
          this._createSubmitBtn(this._debouncedSubmit),
          <Button
            key="close"
            type="primary"
            onClick={this._closeDialog}
            style={{ marginLeft: '10px' }}
          >
            Close
          </Button>
        ]}
      >
        <Spin tip="Initializing..." spinning={false}>
          {this._createOperationBar()}

          {/* <SplitPane split="horizontal" defaultSize={splitPaneTopSize}>
            <div style={{ overflow: 'hidden', flexGrow: '1' }}>
              {this._createPositionsGrid()}
            </div>
            {this._createTrackOrdGrid()}
          </SplitPane> */}

          <div>
            {this._createPositionsGrid()}
            {this._createTrackOrdGrid()}
          </div>

          {this._createSummaryPanel()}
          {this._createErrorsPanel()}
        </Spin>
      </Modal>
    );
  }
}

export default RollPositionsDialog;
