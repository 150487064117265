import _ from 'lodash';
import TickerUtils from 'common/utils/TickerUtils';
import moment from 'moment';
import Order from '../Order';

const { parseAssetClass, parseExch, AssetClass } = TickerUtils;

const MARKET_US = 'US';
const ALGO_INLINE = 'INLINE';
const VENUE_CONFIG = [
  {
    name: 'CATS',
    filters: [
      {
        funds: ['CVF', 'SLHL', 'DCL'],
        brokers: ['CITIC', 'CITIC_MARGIN', 'DONGWU', 'YONGAN', 'DFZQ']
      }
    ]
  },
  {
    name: 'CICCIMS',
    filters: [
      {
        brokers: ['CICC', 'CICC_MARGIN'],
        cfdTypes: [null]
      },
      {
        brokers: ['CISP'],
        cfdTypes: ['CFD']
      }
      // {
      //   funds: ['DCL'],
      //   brokers: ['CITIC', 'DONGWU', 'YONGAN', 'DFZQ']
      // }
    ]
  },
  {
    name: 'XUNTOU',
    filters: [
      {
        brokers: [
          'CMS',
          'CMS_MARGIN',
          'GTJA',
          'GTJA_MARGIN',
          'GUOXIN',
          'GUOXIN_MARGIN'
        ]
      },
      {
        funds: ['ZJNF'],
        brokers: ['CITIC', 'DONGWU', 'YONGAN']
      }
    ]
  },
  {
    name: 'ATX',
    filters: [
      {
        brokers: ['CICC'],
        cfdTypes: ['CFD']
      }
    ]
  },
  {
    name: 'FIX',
    filters: [
      {
        brokers: ['CICB']
      }
    ]
  },
  {
    name: 'MANUAL',
    filters: [
      {
        brokers: ['ZZZB']
      }
    ]
  }
];

const EXEC_CALC_FIELDS = [
  'fundCode',
  'custodian',
  'cfdType',
  'ticker',
  ['settlementCcy', 'broker'],
  'account'
];

const ALGO_PARAM_TGT_VOL = 'Target Vol %';
const ALGO_PARAM_MAX_VOL = 'Max Vol %';
const ALGO_PARAM_MIN_VOL = 'Min Vol %';
const ALGO_PARAM_START_TIME = 'Start Time';
const ALGO_PARAM_END_TIME = 'End Time';
const ALGO_PARAM_VOL_FIELDS = [ALGO_PARAM_TGT_VOL, ALGO_PARAM_MIN_VOL];

const ALGO_TWAP = 'TWAP';
const ALGO_VWAP = 'VWAP';
const ALGO_IS = 'IS';

const MAX_VAL_MAP = {
  HSAP: 30,
  CLSW: 20,
  ZXGY: 20,
  CITIC: 30,
  CITIC_MARGIN: 30,
  SGEN: 30,
  SGSW: 30
};

const CH_INLINE_VOL_MAP = {
  MACX: 25,
  SGSW: 20
};

const _calcVenue = route => {
  const { fundCode, broker, venue, cfdType } = route;
  const venueInfo = VENUE_CONFIG.find(c =>
    c.filters.find(
      f =>
        f.brokers.includes(broker) &&
        (_.isEmpty(f.funds) || f.funds.includes(fundCode)) &&
        (_.isEmpty(f.cfdTypes) || f.cfdTypes.includes(cfdType))
    )
  );

  return {
    venue:
      (venueInfo && venueInfo.name) ||
      (['MANUAL'].includes(venue) ? venue : 'EMSX')
  };
};

const _calcHandlingInstruction = (route, ctx) => {
  const { brokerMap = {} } = ctx;
  const { broker, ticker } = route;
  const selectedBroker = brokerMap[broker];
  if (!selectedBroker) return {};

  let handlingInstruction = (
    selectedBroker.handlingInstruction || 'LT'
  ).startsWith('HT')
    ? 'HT'
    : 'LT';

  if (['KOTD'].includes(broker)) {
    handlingInstruction =
      parseAssetClass(ticker) === AssetClass.OPT ? 'HT' : 'LT';
  }

  // if (['CICC'].includes(broker) && cfdType === 'CFD') {
  //   handlingInstruction = ["DMA", "TWAP"].includes(algoCode) ? "LT" : "HT";
  // }

  if (broker === 'CICB') {
    handlingInstruction = 'LT';
  }

  return {
    handlingInstruction
  };
};

const _calcAlgo = (route, ctx) => {
  const { broker, order, valueChanges } = route;
  const { book, ticker, algoParams = {}, algoCode } = order;

  if (_.isEmpty(valueChanges)) return {};

  if (!valueChanges.includes('CISP')) return {};

  const exch = parseExch(ticker);
  let newAlgoCode = algoCode;
  if (
    ['CISP'].includes(broker) &&
    [ALGO_INLINE, ALGO_IS].includes(newAlgoCode)
  ) {
    newAlgoCode = ALGO_TWAP;
    algoParams[ALGO_PARAM_TGT_VOL] = null;
  }

  const updatedAlgoParams = _.fromPairs(
    ALGO_PARAM_VOL_FIELDS.map(volField => {
      if (!(volField in algoParams)) return null;

      const vol = algoParams[volField];
      const orderLeftQty = Order.calcLeftQty(order);
      let newVol =
        orderLeftQty !== 0 ? _.round(vol * (route.qty / orderLeftQty)) : vol;

      // max limit on vol value.
      newVol = ['CLSW', 'ZXGY'].includes(broker)
        ? Math.min(Math.floor(newVol), 19)
        : Math.min(Math.floor(newVol), 29);

      if (
        [ALGO_IS, ALGO_INLINE].includes(newAlgoCode) &&
        Object.keys(CH_INLINE_VOL_MAP).includes(broker) &&
        ['CH', 'C1', 'C2'].includes(exch)
      ) {
        newVol = Math.min(Math.floor(newVol), CH_INLINE_VOL_MAP[broker]);
      }

      return [volField, newVol < 1 ? 1 : newVol];
    }).filter(Boolean)
  );

  if ([ALGO_TWAP, ALGO_VWAP].includes(newAlgoCode)) {
    const maxVol = updatedAlgoParams[ALGO_PARAM_MAX_VOL];
    const defaultMaxVol = MAX_VAL_MAP[broker] ? MAX_VAL_MAP[broker] : 49;
    // eslint-disable-next-line max-len
    updatedAlgoParams[ALGO_PARAM_MAX_VOL] = maxVol
      ? Math.min(maxVol, defaultMaxVol)
      : defaultMaxVol;
  }

  // eslint-disable-next-line no-empty
  if (
    ALGO_IS === newAlgoCode &&
    updatedAlgoParams[ALGO_PARAM_TGT_VOL] &&
    updatedAlgoParams[ALGO_PARAM_TGT_VOL] ===
      updatedAlgoParams[ALGO_PARAM_MIN_VOL]
  ) {
    updatedAlgoParams[ALGO_PARAM_TGT_VOL] += 1;
  }

  // Add T11 platcny hk & platusd hk/ch default algo start time
  // update to hk/ch default algo start time
  const defaultAlgoTime = '09:30:00';
  const currentTime = moment()
    .utcOffset(8)
    .format('HH:mm:ss');
  if (
    book === 'T11' &&
    currentTime < defaultAlgoTime &&
    _.isEmpty(algoParams[ALGO_PARAM_START_TIME]) &&
    (ticker.endsWith('HK Equity') || ticker.endsWith('CH Equity'))
  ) {
    algoParams[ALGO_PARAM_START_TIME] = defaultAlgoTime;
  }

  return {
    algoCode: newAlgoCode,
    algoParams: {
      ...algoParams,
      ...updatedAlgoParams
    }
  };
};

const _calcAlgoParams = route => {
  const { algoCode, algoMapping = {}, broker, venue, order, bookCode } = route;
  const { ticker } = order;
  const algoParamInfos = algoMapping[algoCode] || [];
  const algoParams = _.fromPairs(
    algoParamInfos.filter(p => p.value).map(p => [p.code, p.value])
  );

  const exch = parseExch(ticker);
  const updatedAlgoParams = _.fromPairs(
    ALGO_PARAM_VOL_FIELDS.map(volField => {
      if (!(volField in algoParams)) return null;

      const vol = algoParams[volField];
      // max limit on vol value.
      let newVol = ['CLSW', 'ZXGY'].includes(broker)
        ? Math.min(Math.floor(vol), 19)
        : Math.min(Math.floor(vol), 29);

      if (
        [ALGO_IS, ALGO_INLINE].includes(algoCode) &&
        Object.keys(CH_INLINE_VOL_MAP).includes(broker) &&
        ['CH', 'C1', 'C2'].includes(exch)
      ) {
        newVol = Math.min(Math.floor(newVol), CH_INLINE_VOL_MAP[broker]);
      }
      return [volField, newVol < 1 ? 1 : newVol];
    }).filter(Boolean)
  );

  if ([ALGO_TWAP, ALGO_VWAP].includes(algoCode)) {
    const maxVol = algoParams[ALGO_PARAM_MAX_VOL];
    const defaultMaxVol = MAX_VAL_MAP[broker] ? MAX_VAL_MAP[broker] : 49;
    // eslint-disable-next-line max-len
    updatedAlgoParams[ALGO_PARAM_MAX_VOL] = maxVol
      ? Math.min(maxVol, defaultMaxVol)
      : defaultMaxVol;
  }

  if (
    bookCode === 'T95' &&
    _.isEmpty(algoParams[ALGO_PARAM_END_TIME]) &&
    [ALGO_TWAP, ALGO_VWAP].includes(algoCode) &&
    ['HK'].includes(exch)
  ) {
    algoParams[ALGO_PARAM_END_TIME] = '15:50:00';
  }

  if (
    broker === 'ZXGY' &&
    venue === 'EMSX' &&
    ['HK', 'CH'].includes(exch) &&
    !algoParams[ALGO_PARAM_END_TIME]
  ) {
    updatedAlgoParams[ALGO_PARAM_END_TIME] = ['HK'].includes(exch)
      ? '16:10:00'
      : '15:00:00';
  }

  return {
    algoParams: {
      ...algoParams,
      ...updatedAlgoParams
    }
  };
};

const _calcRouteAlgoParams = route => {
  const { algoCode, algoParams, broker } = route;

  const updatedAlgoParams = _.fromPairs(
    ALGO_PARAM_VOL_FIELDS.map(volField => {
      if (!(volField in algoParams)) return null;

      const vol = algoParams[volField];
      // max limit on vol value.
      const newVol = ['CLSW', 'ZXGY'].includes(broker)
        ? Math.min(Math.floor(vol), 19)
        : Math.min(Math.floor(vol), 29);
      return [volField, newVol < 1 ? 1 : newVol];
    }).filter(Boolean)
  );

  if ([ALGO_TWAP, ALGO_VWAP].includes(algoCode)) {
    const maxVol = algoParams[ALGO_PARAM_MAX_VOL];
    const defaultMaxVol = MAX_VAL_MAP[broker] ? MAX_VAL_MAP[broker] : 49;
    // eslint-disable-next-line max-len
    updatedAlgoParams[ALGO_PARAM_MAX_VOL] = maxVol
      ? Math.min(maxVol, defaultMaxVol)
      : defaultMaxVol;
  }

  return {
    algoParams: {
      ...algoParams,
      ...updatedAlgoParams
    }
  };
};

const _calcIsDirectLine = (route, ctx) => {
  const {
    refId,
    venue,
    broker,
    side,
    ticker,
    bookCode,
    order,
    borrowRate
  } = route;
  const { brokerMap = {} } = ctx;

  const isBrokerEnableDirL = !!(brokerMap[broker] || {}).enableDirectLine;
  const assetClass = parseAssetClass(ticker);
  const isSpecialGTCOrder = bookCode === 'T45' && order.timeInForce === 'GTC';
  const isCLSWShrtRoute = broker === 'CLSW' && ['SHRT', 'COVR'].includes(side);
  const exch = parseExch(order.ticker);

  // Value of directline is different between new/edit mode.
  const isDirectLine = refId
    ? ['EMSX', 'FIX'].includes(venue) &&
      isBrokerEnableDirL &&
      !(broker === 'ZXGY' && exch === 'CH') &&
      !isSpecialGTCOrder &&
      !isCLSWShrtRoute
    : (isBrokerEnableDirL || !['EMSX'].includes(venue)) &&
      !(
        !['PBBJ', 'HTFD'].includes(broker) &&
        side === 'SHRT' &&
        assetClass === AssetClass.EQTY &&
        _.isNil(borrowRate)
      ) &&
      !isSpecialGTCOrder &&
      !isCLSWShrtRoute;

  return {
    isDirectLine
  };
};

const _calcInstruction = route => {
  const {
    algoCode,
    algoParams = {},
    order: { pmReason, ticker }
  } = route;

  const algoParamValuesString = _.isEmpty(algoParams)
    ? null
    : Object.entries(algoParams)
        .filter(([, v]) => v && v !== '')
        .map(([k, v]) => {
          if (k.endsWith('Time')) {
            return `${k.replace(' Time', '')}: ${v}`;
          }
          return k.endsWith('%') ? `${v}%` : `${v}`;
        })
        .join(',');

  const specialReason =
    !_.isNil(pmReason) && pmReason.includes('[ROLL]') ? '[ROLL]' : null;

  let instruction = [algoCode, algoParamValuesString, specialReason]
    .filter(Boolean)
    .join('-');

  if (MARKET_US === parseExch(ticker) && ALGO_INLINE === algoCode) {
    instruction = `${instruction}-30% in dark`;
  }

  return {
    instruction
  };
};

const _calcBorrowRate = (route, ctx) => {
  if (!route.loanType || route.loanType !== 'PTH') {
    return {
      borrowRate: null
    };
  }

  const { extraHoldingsByTicker = {}, holdingsByTicker = {} } = ctx;
  const {
    fundCode,
    bookCode,
    custodian,
    order: { ticker }
  } = route;
  let pthHolding;
  if (extraHoldingsByTicker[ticker]) {
    pthHolding = _.first(
      extraHoldingsByTicker[ticker].filter(
        h =>
          h.positionTypeCode === 'PTH' &&
          h.fundCode === fundCode &&
          h.bookCode === bookCode &&
          h.custodianCode === custodian &&
          h.ticker === route.ticker
      )
    );
  }
  let borrowRate = pthHolding ? pthHolding.borrowRate : null;

  if (borrowRate === null && holdingsByTicker[ticker]) {
    const holding = _.first(
      holdingsByTicker[ticker].filter(
        h =>
          h.direction === 'SHORT' &&
          h.fundCode === fundCode &&
          h.bookCode === bookCode &&
          h.custodianCode === custodian &&
          h.ticker === route.ticker
      )
    );
    borrowRate = holding ? holding.borrowRate : null;
  }

  return {
    borrowRate
  };
};

const _routeCalculatorMap = {
  broker: [
    _calcVenue,
    _calcIsDirectLine,
    _calcAlgo,
    _calcAlgoParams,
    _calcHandlingInstruction,
    _calcInstruction
  ],
  custodian: [_calcVenue, _calcIsDirectLine, _calcBorrowRate],
  algoCode: [_calcAlgoParams, _calcHandlingInstruction, _calcInstruction],
  venue: [_calcIsDirectLine],
  algoParams: [_calcInstruction, _calcRouteAlgoParams],
  orderType: [_calcIsDirectLine],
  borrowRate: [_calcIsDirectLine],
  loanType: [_calcBorrowRate]
};

const RouteCalculator = {
  calcByField(route, field, ctx = {}) {
    const calculators = _routeCalculatorMap[field] || [];
    return calculators.reduce((prev, fn) => {
      const result = fn({ ...route, ...prev }, ctx);
      return { ...prev, ...result };
    }, {});
  },
  calcByFields(route, fields, ctx = {}) {
    return fields.reduce((prev, f) => {
      const result = this.calcByField({ ...route, ...prev }, f, ctx);
      return { ...prev, ...result };
    }, {});
  },
  calcExecFields(fieldName) {
    const fieldIndex = _.findIndex(EXEC_CALC_FIELDS, f =>
      _.isArray(f) ? f.includes(fieldName) : f === fieldName
    );
    const overrideFields = _.flatten(EXEC_CALC_FIELDS.slice(0, fieldIndex + 1));
    const resultFields = _.flatten(EXEC_CALC_FIELDS.slice(fieldIndex + 1));

    return { overrideFields, resultFields };
  },
  computeChangesFromHolding(route, holding, row = 0) {
    const {
      order: { leftQuantity = 0 },
      side
    } = route;
    const changes = [
      'fundCode',
      'bookCode',
      'qty',
      'ticker',
      'custodian',
      'cfdType'
    ].reduce((prev, field) => {
      if (field === 'qty') {
        if (['SELL', 'COVR'].includes(side)) {
          const qty =
            Math.abs(holding.theoreticalQtyForClose) > leftQuantity
              ? leftQuantity
              : Math.abs(holding.theoreticalQtyForClose);
          return [...prev, [row, field, route[field], qty]];
        } else {
          return prev;
        }
      } else {
        const holdingField = field === 'custodian' ? 'custodianCode' : field;
        return [...prev, [row, field, route[field], holding[holdingField]]];
      }
    }, []);
    return changes;
  }
};

export default RouteCalculator;
