import React, { PureComponent } from 'react';
import { Modal, Button } from 'antd';
import { DIALOG_CANCEL_ROUTES } from '../../omsConstants';
import client from '../../api/client';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { cancelRoutesGridColumns } from './GridColumnMap';

class CancelRoutesDialog extends PureComponent {
  state = {
    submitStatus: 'READY',
    extIdListTableColumns: [
      {
        data: 'refId',
        headerName: 'Ref Id',
        readOnly: true
      }
    ],
    gridWrapperStyle: {
      width: '100%',
      height: '300px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    }
  };

  componentDidMount() {
    const { selectedRoutes } = this.props;

    const cmds = selectedRoutes.map(r => ({
      refId: r.refId
    }));

    const extIdListTableSetting = this._createExtIdListGridSettings();
    const extIdNullList = selectedRoutes.filter(
      r => !r.externalId || !r.isDirectLine
    );

    this.setState({
      cmds,
      extIdNullList,
      extIdListTableSetting
    });
  }

  _cancelRoutes = () => {
    const { cmds } = this.state;
    const { submitDialogSuccess } = this.props;

    if (cmds.length > 0) {
      return client.cancelRoutes(cmds).then(() => {
        submitDialogSuccess(DIALOG_CANCEL_ROUTES, cmds.map(c => c.refId));
      });
    }

    return null;
  };

  _createExtIdNullTable = () => {
    const {
      extIdNullList,
      extIdListTableSetting,
      gridWrapperStyle
    } = this.state;
    const warningMsgs = [
      'The follow routes ext id is null and cancel instructions will not be sent to brokers.',
      'Some of the following routes to the specified broker is not directline.'
    ];
    return (
      !_.isEmpty(extIdNullList) && (
        <>
          <Message warning list={warningMsgs} style={{ marginTop: '3px' }} />
          <div style={gridWrapperStyle}>
            <HotTable
              ref={this.hotTblRef}
              data={extIdNullList}
              manualColumnResize={true}
              {...extIdListTableSetting}
            />
          </div>
        </>
      )
    );
  };

  _createExtIdListGridSettings = () => {
    return hotTableUtils.createSettings({
      columns: cancelRoutesGridColumns,
      rowHeaders: true,
      contextMenu: {}
    });
  };

  _onSubmit = () => {
    const promises = [this._cancelRoutes()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_CANCEL_ROUTES);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  render() {
    return (
      <Modal
        width={1200}
        maskClosable={false}
        title="Cancel Routes"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <p>Are you sure you want to cancel the selected routes?</p>
        {this._createExtIdNullTable()}
      </Modal>
    );
  }
}

export default CancelRoutesDialog;
